import OktaSignIn from '@okta/okta-signin-widget/dist/js/okta-sign-in.js'
import $ from 'jquery/dist/jquery.min.js'
import Inputmask from "inputmask";

function loadMobileWidget(data){
  var signInWidgetConfig = {
    baseUrl: data.okta_base_url,
    authParams: {
      pkce: false,
      responseType: 'code'
    },
    language: data.language,
    i18n: {
      //Overrides default text when using English. Override other languages by adding additional sections.
      'en': {
        'primaryauth.username.placeholder': i18n.t('okta.login.input.username_placeholder'),
        'primaryauth.password.placeholder': i18n.t('okta.login.input.last_4_ssn'),
        'primaryauth.submit': i18n.t('okta.login.control.send_text_message'),
        'error.username.required': i18n.t('okta.login.validation.username_required'),
        'error.password.required': i18n.t('okta.login.validation.password_required')
      },
      'es': {
        'primaryauth.username.placeholder': i18n.t('okta.login.input.username_placeholder'),
        'primaryauth.password.placeholder': i18n.t('okta.login.input.last_4_ssn'),
        'primaryauth.submit': i18n.t('okta.login.control.send_text_message'),
        'error.username.required': i18n.t('okta.login.validation.username_required'),
        'error.password.required': i18n.t('okta.login.validation.password_required')
      }
    },
    // Changes to widget functionality
    features: {
      rememberMe: false,
      multiOptionalFactorEnroll: true,
      registration: true,
      router: true,
    },
    transformUsername: function (username, operation) {
      return username.replace(/-/g, "") + "+" + $("#okta-signin-password").val();
    },
    customButtons: [
      {
        title: i18n.t('okta.login.control.sign_in_with_credentials'),
        className: 'link',
        click: function () {
          // clicking on the button navigates to another page
          window.location.href = data.credential_login_path;
        }
      }
    ],
  }

  function loadWidget(){
    var signInWidget = new OktaSignIn(signInWidgetConfig);

    signInWidget.renderEl({
      el: '#widget-container'
    },function success(res) {
      if (res.status === 'SUCCESS') {
        var token = res.session;
        document.getElementById("session-token").value = token.token
        document.getElementById("f1").submit();
        button1.style.display = 'block'
      } else {
      }
    });

    signInWidget.on('afterRender', function (context) {

      $(".auth-divider").remove();
      $('h2.okta-form-title').remove();
      $('.widget-parent-container').show();

      // force widget to use this url as the base path
      if (data["widget_base_path"]) {
        var basePath = data["widget_base_path"]
        // the basePath needs to have a trailing slash and
        // not have a leading slash for the formula below to work
        if( basePath.slice(-1) != "/" ){
          basePath = basePath + "/"
        }
        if( basePath.charAt(0) == "/" ){
          basePath = basePath.substring(1)
        }

        var path = Backbone.history.fragment.replace(basePath, "/");
        if (path !== "" && path.indexOf("/") >= 0) {
          Backbone.history.root = "/" + basePath;
          Backbone.history.navigate(path, { trigger: true, replace: true })
        }
      }

      if (context.controller == 'primary-auth') {

        $(".auth-header").hide();
        $(".auth-footer").hide();
        $(".o-form-input-name-remember").parent().hide();
        $("#help-links-container").css("display", "block");
        var username = $("#okta-signin-username");
        username.attr("placeholder", "___-___-____").attr("name", "mobile").attr("autocomplete", "off")
        var im = new Inputmask('999-999-9999')
        im.mask(username[0])

        // hack to disable autocomplete
        $("#form1").hide()
        username.val("\b")
        setTimeout(function(){
          $("#okta-signin-username").val("")
          $("#form1").show()
        },1000);

        return;
      }
      else if(context.controller == 'mfa-verify'){
        // automatically send sms
        setTimeout(function(){
          var evt = new MouseEvent("click");
          $("a[data-se=sms-send-code]")[0].dispatchEvent(evt);
        });
      }
    });
  }

  // SSO with Okta
  $.ajax({
    url: data.okta_base_url + "/api/v1/sessions/me",
    type: "GET",
    xhrFields: {
      withCredentials: true
    },
    success: function (response) {
      if (response.status === 'ACTIVE') {
        $('#f1').submit();
      }
    },
    error: function (xhr, status) {
      loadWidget();
      $("#widget-loading").hide();
    }
  });
}

export default loadMobileWidget;
