import OktaSignIn from '@okta/okta-signin-widget/dist/js/okta-sign-in.js'
import $ from 'jquery/dist/jquery.min.js'

function loadCredentialWidget(data){
  var globalUsername = "";
  var widgetContext = "";

  var signInWidgetConfig = {
    baseUrl: data.okta_base_url,
    authParams: {
      pkce: false,
      responseType: 'code',
    },
    language: data.language,
    i18n: {
      //Overrides default text when using English. Override other languages by adding additional sections.
      'en': {
        'primaryauth.username.placeholder': i18n.t('okta.login.input.username'),
        'primaryauth.password.placeholder': i18n.t('okta.login.input.password'),
        'primaryauth.submit': i18n.t('okta.login.control.sign_in'),
        'password.reset': i18n.t('okta.login.control.change_password'),
        'password.forgot.sendEmail': i18n.t('okta.login.control.change_password'),
        'registration.form.title': i18n.t('okta.login.title.create_account'),
        'oie.registration.form.submit': i18n.t('okta.login.control.get_started'),
        'signout': i18n.t('okta.login.control.back_to_sign_in')
      },
      'es': {
        'primaryauth.username.placeholder': i18n.t('okta.login.input.username'),
        'primaryauth.password.placeholder': i18n.t('okta.login.input.password'),
        'primaryauth.submit': i18n.t('okta.login.control.sign_in'),
        'password.reset': i18n.t('okta.login.control.change_password'),
        'password.forgot.sendEmail': i18n.t('okta.login.control.change_password'),
        'registration.form.title': i18n.t('okta.login.title.create_account'),
        'oie.registration.form.submit': i18n.t('okta.login.control.get_started'),
        'signout': i18n.t('okta.login.control.back_to_sign_in')
      }
    },
    // Changes to widget functionality
    features: {
      rememberMe: false,
      multiOptionalFactorEnroll: true,
      router: true,
    },
    customButtons: [
      {
        title: i18n.t('okta.login.control.sign_in_text_message_alternative'),
        className: 'link',
        click: function () {
          // clicking on the button navigates to another page
          window.location.href = data.mobile_login_path;
        }
      }
    ],
    transformUsername: function (username, operation) {
      if( widgetContext == 'forgot-password'){
        return globalUsername;
      }
      else {
        return username;
      }
    },
  }
  function validateEmail($email) {
    var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
    return emailReg.test($email);
  }
  function validateSSN($ssn) {
    var ssnReg = /^[0-9]{4}$/;
    return ssnReg.test($ssn);
  }

  function loadWidget(){
    var signInWidget = new OktaSignIn(signInWidgetConfig);

    signInWidget.renderEl({
      el: '#widget-container'
    },function success(res) {
      if (res.status === 'SUCCESS') {
        var token = res.session;
        document.getElementById("session-token").value = token.token
        document.getElementById("f1").submit();
      } else {
      }
    });

    signInWidget.on('afterRender', function (context) {
      // clear footer links every time so they don't duplicate
      $("#widget-footer-links").empty()

      $(".auth-divider").remove();
      $('.widget-parent-container').show();

      // force widget to use this url as the base path
      if (data["widget_base_path"]) {
        var basePath = data["widget_base_path"]
        // the basePath needs to have a trailing slash and
        // not have a leading slash for the formula below to work
        if( basePath.slice(-1) != "/" ){
          basePath = basePath + "/"
        }
        if( basePath.charAt(0) == "/" ){
          basePath = basePath.substring(1)
        }

        var path = Backbone.history.fragment.replace(basePath, "/");
        if (path !== "" && path.indexOf("/") >= 0) {
          Backbone.history.root = "/" + basePath;
          Backbone.history.navigate(path, { trigger: true, replace: true })
        }
      }

      $('.registration-link').html('Get Started')
      widgetContext = context.controller

      if (context.controller == 'primary-auth') { 
        $(".auth-header").hide();
        $(".auth-footer").hide();
        $(".o-form-input-name-remember").parent().hide();
        $("#help-links-container").css("display", "block");
        $("#okta-signin-username").attr("placeholder", "username");
        $("#widget-footer-links").append('<div class="item"><a class="d-inline-block mt-1 link okta-forgot-password " data-se="forgot-password">'+ i18n.t("okta.login.control.forgot_password") + '</a></div>');
        $(".okta-forgot-password").click(function () {
          $(".js-forgot-password")[0].click();
        });
        $('.o-form-input-name-password').closest('.o-form-fieldset').addClass('okta-password').append('<span class="form-text">'+ i18n.t("okta.login.validation.password_validation") +'</span>');
        $('h2.okta-form-title').remove();
        return;
      }
      if (context.controller == 'forgot-password') {
        var captchaEnabled = data["recaptcha_enabled"];
        var captchaSitekey = data["recaptcha_sitekey"];
        var lookupUsername;
        var recaptchaWidget;

        if(captchaEnabled) {
          var captchaEl = $('<div id="recaptcha" class="g-recaptcha" data-size="invisible"></div>');
          captchaEl.appendTo('.forgot-password')
          recaptchaWidget = grecaptcha.render(captchaEl[0], {
            'sitekey':  captchaSitekey,
            'callback': function(token){ lookupUsername(token); }
          });
        }

        $('.forgot-password').children('form').attr('novalidate', 'novalidate')
        $('.okta-sign-in-header.auth-header').css("display", "none");
        $(".email-button").hide();

        $('[for="account-recovery-username"]').html(i18n.t('okta.login.input.enter_email_address'));
        $('.o-form-fieldset').after('<div class="o-form-fieldset o-form-label-top account-recovery-email"></div>')

        $('.account-recovery-email').after('<div class="o-form-fieldset o-form-label-top last-4-ssn"><div class="okta-form-label o-form-label"><label for="last-4-ssn">'+ i18n.t("okta.login.input.enter_last_4_ssn") +'</label></div><div class="o-form-input"><span class="o-form-input-last-4-ssn o-form-control okta-form-input-field input-fix"><input type="password" minlength="4" maxlength="4" id="last-4-ssn"></span><p id="input-container-error9" class="okta-form-input-error o-form-input-error o-form-explain" role="alert"><span class="icon icon-16 error-16-small" role="img" aria-label="Error"></span>'+ i18n.t("okta.login.validation.field_cannot_be_left_blank") +'</p></div></div>');

        $('.last-4-ssn').after('<a data-se="custom-email-button" class="button button-primary button-wide custom-email-button link-button" href="#">'+ i18n.t("okta.login.control.request_change_password") +'</a>')

        $(".custom-email-button").click(function (e) {
          e.preventDefault();
          //get last 4 ssn and email
          var email = $("#account-recovery-username").val();
          var last_4_ssn = $("#last-4-ssn").val();
          var isValidEmail = false;
          var isValidSSN = false;
          if (!email) {
            isValidEmail = false
            $('.o-form-input-name-username').parent().addClass('o-form-has-errors')
            if ($('.o-form-input-name-username').next('p').length == 0) {
              $('.o-form-input-name-username').after('<p id="input-container-error9" class="okta-form-input-error o-form-input-error o-form-explain" role="alert"><span class="icon icon-16 error-16-small" role="img" aria-label="Error"></span>'+ i18n.t("okta.login.validation.email_is_required") +'</p>')
            } else {
              $('.o-form-input-name-username').next('p').html('<span class="icon icon-16 error-16-small" role="img" aria-label="Error"></span>'+ i18n.t("okta.login.validation.email_is_required") )
            }

          } else if (!validateEmail(email)) {
            isValidEmail = false
            $('.o-form-input-name-username').parent().addClass('o-form-has-errors');
            if ($('.o-form-input-name-username').next('p').length == 0) {
              $('.o-form-input-name-username').after('<p id="input-container-error9" class="okta-form-input-error o-form-input-error o-form-explain" role="alert"><span class="icon icon-16 error-16-small" role="img" aria-label="Error"></span>'+ i18n.t("okta.login.validation.invalid_mail") +'</p>')
            }
            else {
              $('.o-form-input-name-username').next('p').html('<span class="icon icon-16 error-16-small" role="img" aria-label="Error"></span>' + i18n.t("okta.login.validation.invalid_mail"))
            }
          }
          else {
            isValidEmail = true
            $('.o-form-input-name-username').parent().removeClass('o-form-has-errors')
          }
          if (!last_4_ssn) {
            isValidSSN = false
            $('.o-form-input-last-4-ssn').parent().addClass('o-form-has-errors')
            if ($('.o-form-input-last-4-ssn').next('p').length == 0) {
              $('.o-form-input-last-4-ssn').after('<p id="input-container-error9" class="okta-form-input-error o-form-input-error o-form-explain" role="alert"><span class="icon icon-16 error-16-small" role="img" aria-label="Error"></span>'+ i18n.t("okta.login.validation.last_4_ssn_required") +'</p>')
            } else {
              $('.o-form-input-last-4-ssn').next('p').html('<span class="icon icon-16 error-16-small" role="img" aria-label="Error"></span>' + i18n.t("okta.login.validation.last_4_ssn_required"))
            }
          } else if (!validateSSN(last_4_ssn)) {
            isValidSSN = false
            $('.o-form-input-last-4-ssn').parent().addClass('o-form-has-errors')
            if ($('.o-form-input-last-4-ssn').next('p').length == 0) {
              $('.o-form-input-last-4-ssn').after('<p id="input-container-error9" class="okta-form-input-error o-form-input-error o-form-explain" role="alert"><span class="icon icon-16 error-16-small" role="img" aria-label="Error"></span>'+ i18n.t("okta.login.validation.invalid_last_4_ssn") +'</p>')
            } else {
              $('.o-form-input-last-4-ssn').next('p').html('<span class="icon icon-16 error-16-small" role="img" aria-label="Error"></span>' + i18n.t("okta.login.validation.invalid_last_4_ssn"))
            }
          }
          else {
            isValidSSN = true
            $('.o-form-input-last-4-ssn').parent().removeClass('o-form-has-errors')
          }

          if (!isValidEmail || !isValidSSN)
            return;

          // define lookup here to be in scope to get email and last_4_ssn after captcha
          // is passed and form is valid
          lookupUsername = function(recaptchaToken){
            // api call to Get Username by Email and Last 4 ssn
            $.ajax({
              url: data.reset_lookup_path,
              type: 'POST',
              beforeSend: function(xhr) { xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')) },
              data: {
                email: email,
                last_4_ssn: last_4_ssn,
                'g-recaptcha-response': recaptchaToken
              },
              success: function(response) {
                globalUsername = response.username;
                $(".email-button")[0].click();
              },
              error: function(jqXHR){
                $('.o-form-error-container').empty();
                $('.o-form-error-container').append('<div class="okta-form-infobox-error infobox infobox-error" role="alert"><span class="icon error-16"></span><p>'+ i18n.t("okta.login.validation.unable_to_find_account") +'</p></div>')
              }
            });
          };

          if(captchaEnabled) {
            grecaptcha.execute(recaptchaWidget);
          }
          else {
            lookupUsername(null);
          }
        });
        return;
      }
      else {
         $(".auth-header").hide();
      }
    });
  }

  // SSO with Okta
  $.ajax({
    url: data.okta_base_url + "/api/v1/sessions/me",
    type: "GET",
    xhrFields: {
      withCredentials: true
    },
    success: function (response) {
      console.log(response);
      if (response.status === 'ACTIVE') {
        $('#f1').submit();
      }
    },
    error: function (xhr, status) {
      loadWidget();
      $("#widget-loading").hide();
    }
  });
}

export default loadCredentialWidget;
