import loadMobileWidget from './mobile_login.js';
import loadCredentialWidget from './credential_login.js';
import { en, es } from './i18n';

window.i18n.store(en);
window.i18n.store(es);

export default function loadWidget(data) {
  if (data.widget === 'mobile') {
    loadMobileWidget(data);
  } else if (data.widget === 'credential') {
    loadCredentialWidget(data);
  } else {
    throw new Error('unsupported widget');
  }
}
