import loadWidget from '../okta_signin_widget/index.js';

document.addEventListener('DOMContentLoaded', () => {
  try {
    const node = document.getElementById('okta_widget_data');

    const data = JSON.parse(node.getAttribute('data'));

    loadWidget(data);
  } catch (e) {
    // Ignore error
  }
});
