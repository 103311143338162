export default {
  es: {
    okta: {
      login: {
        title: {
          create_account: 'Crear su cuenta de MyAcima',
        },
        control: {
          sign_in_text_message_alternative: 'Iniciar Sesión por Mensaje de Texto',
          sign_in_with_credentials: 'Iniciar Sesión con las Credenciales',
          send_text_message: 'Enviar mensaje de texto',
          sign_in: 'Iniciar Sesión',
          get_started: 'Primeros pasos',
          back_to_sign_in: 'Volver al Inicio de Sesión',
          forgot_password: '¿Olvidó su contraseña o nombre de usuario?',
          request_change_password: 'Solicitar cambiar la contraseña',
          change_password: 'Cambiar la contraseña'
        },
        input: {
          username: 'Nombre de Usuario',
          password: 'Contraseña',
          last_4_ssn: 'Últimos 4 SSN o ITIN; Clientes de Canadá usan un PIN de 4 dígitos',
          enter_last_4_ssn:
            'Ingrese los últimos 4 SSN o ITIN; Clientes de Canadá usan un PIN de 4 dígitos',
          username_placeholder: 'Número de Teléfono Móvil en la Cuenta',
          enter_email_address:
            'Ingrese su dirección de correo electrónico a continuación'
        },
        validation: {
          username_required: 'Ingrese un número de teléfono en la cuenta',
          password_required:
            'Ingrese los últimos 4 dígitos del número de seguridad social',
          password_validation:
            'La contraseña debe tener al menos 12 caracteres',
          email_is_required: 'Se requiere una dirección de correo electrónico',
          invalid_mail: 'Dirección de correo electrónico inválida',
          last_4_ssn_required:
            'Se requieren los últimos 4 SSN, ITIN o PIN',
          invalid_last_4_ssn:
            'Los últimos 4 SSN, ITIN o PIN no son válidos',
          unable_to_find_account: 'El correo electrónico o el SSN, ITIN o PIN son incorrectos',
          field_cannot_be_left_blank: 'Este campo no puede dejarse en blanco'
        }
      }
    }
  }
};
